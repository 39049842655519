import { Injectable } from '@angular/core';

import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { UrlService, CannotBuildUrl } from './url.service';

import { AuthenticationService } from './authentication.service';

import { ProfileService } from './profile.service';

import { InvalidServerResponse, APIError, getAPIError } from './error';

import { ROLE_CLIENT } from './authentication.service';

export interface Account {
    id: number;
    createdISO: string;
    name: string;
    email: string;
}

export type AccountRef = Account | null;

export function isAccount(data: unknown) {
    const account = data as Account;
    return (
        account &&
        typeof account.id === 'number' &&
        typeof account.createdISO === 'string' &&
        typeof account.name === 'string' &&
        typeof account.email === 'string'
    );
}

/* Converts server returned data into an Account object */
export function asAccount(data: any): AccountRef {
    if (!data) {
        return null;
    }
    const account = {
        id: data.id,
        createdISO: data.created,
        name: data.name,
        email: data.email,
    };
    if (isAccount(account)) {
        return account;
    }
    return null;
}

export interface RegistrationDetails {
    readonly accessCode: string;
    readonly name: string;
    readonly email: string;
    readonly password: string;
    readonly country: string;
}

export interface RegistrationDetailsSSO {
    readonly accessCode: string;
    readonly ssoToken: string;
    readonly country: string;
}

export interface RegistrationMagicDetails {
    readonly country: string;
    readonly name: string;
    readonly email: string;
    readonly code: string;
    readonly accessCode: string;
}

function isRegistrationDetails(data: unknown): data is RegistrationDetails {
    const details = data as RegistrationDetails;
    return !!(details && details['email']);
}

@Injectable({
    providedIn: 'root',
})
export class RegistrationService {
    cachedAccount: AccountRef = null;

    constructor(
        private urlService: UrlService,
        private http: HttpClient,
        private authService: AuthenticationService,
        private profileService: ProfileService
    ) {}

    /*
     * Create a new account and return the new object via promise. Otherwise
     * returns a rejected promise resolving to an APIError. On success the
     * account object is cached by this service.
     */
    register(
        details: RegistrationDetails | RegistrationDetailsSSO
    ): Promise<Account> {
        const url = this.urlService.getRegistrationUrl(details.country);
        if (!url) {
            return Promise.reject(
                APIError('unsupported country: ' + details.country)
            );
        }
        let body = {};
        if (isRegistrationDetails(details)) {
            body = {
                name: details.name,
                email: details.email,
                password: details.password,
                invite_code: details.accessCode,
                role: ROLE_CLIENT,
            };
        } else {
            body = {
                sso_token: details.ssoToken,
                invite_code: details.accessCode,
                role: ROLE_CLIENT,
            };
        }
        return this.http
            .post(url, body)
            .toPromise()
            .then((payload: any) => {
                const account = asAccount(payload);
                if (!account) {
                    throw InvalidServerResponse(payload);
                }
                // Update the cache too
                this.cachedAccount = account;
                return account;
            })
            .catch((response: HttpErrorResponse) => {
                throw getAPIError(response);
            });
    }

    /*
     * Create a new account and return the new object via promise. Otherwise
     * returns a rejected promise resolving to an APIError. On success the
     * account object is cached by this service.
     */
    async registerUsingMagicCode(
        details: RegistrationMagicDetails
    ): Promise<Account> {
        const url = this.urlService.getRegistrationUsingMagicCodeUrl(
            details.country
        );
        const body = {
            name: details.name,
            email: details.email,
            magic_code: details.code,
            invite_code: details.accessCode,
            role: ROLE_CLIENT,
        };
        try {
            const payload = await this.http.post(url, body).toPromise();
            const account = asAccount(payload);
            if (account) {
                return account;
            }
            throw InvalidServerResponse(payload);
        } catch (error) {
            throw getAPIError(error);
        }
    }

    async registerUsingMagicCodeAndLogin(
        details: RegistrationMagicDetails
    ): Promise<void> {
        await this.registerUsingMagicCode(details);
        await this.authService.authenticateMagicSignIn({
            country: details.country,
            email: details.email,
            code: details.code,
        });
    }

    /*
     * Create a new account with given registration details and, on success,
     * login and obtain an auth token. Returns a promise that resolves when
     * the whole process is complete.
     */
    registerAndLogin(details: RegistrationDetails): Promise<void> {
        return this.register(details).then((account) => {
            const loginDetails = {
                email: details.email,
                password: details.password,
                country: details.country,
            };
            return this.authService.authenticate(loginDetails);
        });
    }

    async registerAndLoginSSO(details: RegistrationDetailsSSO): Promise<void> {
        let account = null;
        try {
            account = await this.register(details);
        } catch (error) {
            if (error.code !== 409) {
                throw error;
            }
            // The account already exists, so we'll attempt to authenticate below
        }
        await this.authService.authenticateSSO({
            country: details.country,
            ssoToken: details.ssoToken,
        });
    }

    /*
     * Fetch and return our account object from the server. (returns via
     * promise) On success, the object is cached by this service. Note this
     * function always fetching a fresh copy from the server.
     */
    async fetch(): Promise<Account> {
        const url = await this.urlService.getAccountUrl();
        return this.http
            .get(url)
            .toPromise()
            .then((payload: any) => {
                const account = asAccount(payload);
                if (!account) {
                    throw InvalidServerResponse(payload);
                }
                this.cachedAccount = account;
                return account;
            })
            .catch((response: HttpErrorResponse) => {
                throw getAPIError(response);
            });
    }

    /* Returns the cached account instance (if any) with a fallback to fetching
     * a fresh copy from the server. */
    getCachedOrFetch(): Promise<Account> {
        if (this.cachedAccount) {
            return Promise.resolve(this.cachedAccount);
        }
        return this.fetch();
    }
}
