// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host.ng-invalid {
  color: var(--ion-color-danger);
}

.readonly option {
  display: none;
}

.native {
  margin-bottom: var(--app-input-bottom-margin);
  /* In native iOS, when the input is set to 100% width the time text gets
   * centered with apparently no way to left-justify it. */
}
.native .custom-input {
  padding: 0.5em;
  margin-top: 0.5em;
  min-height: 28px;
  background-color: white;
}
.native input {
  width: 100%;
  text-align: left;
  background: transparent;
  border: none;
  margin: 0;
  padding: 0;
}
.native input:focus {
  outline: transparent;
  border: transparent;
}
.native input.ios {
  width: unset;
}

label {
  color: black;
}`, "",{"version":3,"sources":["webpack://./src/app/components/time-selector/time-selector.component.scss"],"names":[],"mappings":"AAAA;EACI,8BAAA;AACJ;;AAEA;EACI,aAAA;AACJ;;AAEA;EACI,6CAAA;EAuBA;0DAAA;AApBJ;AADI;EACI,cAAA;EACA,iBAAA;EACA,gBAAA;EACA,uBAAA;AAGR;AAAI;EACI,WAAA;EACA,gBAAA;EACA,uBAAA;EACA,YAAA;EACA,SAAA;EACA,UAAA;AAER;AACI;EACI,oBAAA;EACA,mBAAA;AACR;AAII;EACI,YAAA;AAFR;;AAMA;EACI,YAAA;AAHJ","sourcesContent":[":host.ng-invalid {\n    color: var(--ion-color-danger);\n}\n\n.readonly option {\n    display: none;\n}\n\n.native {\n    margin-bottom: var(--app-input-bottom-margin);\n\n    .custom-input {\n        padding: 0.5em;\n        margin-top: 0.5em;\n        min-height: 28px;\n        background-color: white;\n    }\n\n    input {\n        width: 100%;\n        text-align: left;\n        background: transparent;\n        border: none;\n        margin: 0;\n        padding: 0;\n    }\n\n    input:focus {\n        outline: transparent;\n        border: transparent;\n    }\n\n    /* In native iOS, when the input is set to 100% width the time text gets\n     * centered with apparently no way to left-justify it. */\n    input.ios {\n        width: unset;\n    }\n}\n\nlabel {\n    color: black;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
