import {
    APIError,
    InvalidServerResponse,
    getAPIError,
    isAPIError,
    isTimeoutError,
    isGenericConnectionError,
} from 'gogetfit-common/error';

import { ROLE_CLIENT } from './authentication.service';

export {
    APIError,
    InvalidServerResponse,
    getAPIError,
    isTimeoutError,
    isGenericConnectionError,
};

// The URL to navigate to if user doesn't have a valid auth token
export const INVALID_TOKEN_URL_PARAM = 'logged-out';
export const INVALID_TOKEN_URL = '/try-again/' + INVALID_TOKEN_URL_PARAM;

/*
 * Used for reporting errors directly to the user.
 */
export class UserFacingError extends Error {}

/* Accepts either HttpErrorResponse, APIError or an Error, and returns an error
 * message suitable for displaying to the user. The purpose of the string is to
 * give (where possible) the user (or tech support) insight into why the error
 * happened. */
export function getUserFacingMessage(obj: any): string {
    if (typeof obj === 'string') {
        return obj;
    }
    if (obj instanceof UserFacingError && obj.message) {
        return obj.message;
    }
    const apiError = getAPIError(obj);
    if (isGenericConnectionError(apiError) || isTimeoutError(apiError)) {
        return 'There was a problem reaching the server. Please check your internet connection.';
    }
    if (isAccountAlreadyExists(apiError)) {
        return 'An account with that email already exists.';
    }
    if (isInviteAlreadyAccepted(apiError)) {
        return 'That access code has already been used.';
    }
    if (apiError.code === 400) {
        // "Bad data" usually means a schema validation error. These messages
        // are generally okay to pass through as-is.
        return apiError.error;
    }
    // Most other errors are too technical for a user to understand, but we'll
    // include it in case the client sends us a screenshot.
    let technical = '';
    if (apiError.code === 0) {
        technical = apiError.error;
    } else {
        technical = apiError.code + ': ' + apiError.error;
    }
    return 'An unexpected error occurred. (' + technical + ')';
}

export function isAccountAlreadyExists(error: APIError): boolean {
    return error.code === 409 && error.error === 'account already exists';
}

export function isInviteAlreadyAccepted(error: APIError): boolean {
    return error.code === 409 && error.error === 'invite already accepted';
}

export function isNoProfileError(error: APIError): boolean {
    return error.code === 400 && error.error === 'no profile for role: client';
}

/* Error means the user has an account, but the account doesn’t have the “CLIENT” role. (ie the user signed in as a pro)
 */
export function isClientRoleRequiredError(error: APIError): boolean {
    return (
        error.code === 403 &&
        error.error === 'you do not have permission to access this resource' &&
        error.details === 'role required: client'
    );
}

/*
 * Whether the error indicates the users auth token is valid, and will never
 * be valid, on the server that issued this response. This could be because
 * the token is corrupted, the server didn't sign the token, or the token is
 * no longer considered valid by the server. (revoked, account removed, etc)
 */
export function isInvalidAuthToken(error: APIError): boolean {
    return !!(
        error &&
        error.code === 400 &&
        error.error.startsWith('Invalid JWT')
    );
}

/*  Whether the error was thrown by UrlService, when it tried to build URL but country code isn't set.
 */
export function isCannotBuildUrlError(error: APIError): boolean {
    return !!(
        error &&
        error.code === 0 &&
        error.error.startsWith('cannot build API url')
    );
}

export function isGeocoderZeroResultsError(error: any): boolean {
    return !!(
        error &&
        typeof error.message === 'string' &&
        error.message.startsWith('GEOCODER_GEOCODE: ZERO_RESULTS:')
    );
}

/* Error means token isn't set and condition is unrecoverable */
export function isAuthorizationRequiredError(error: APIError): boolean {
    return !!(
        error &&
        error.code === 401 &&
        error.error.startsWith('Authorization Required')
    );
}

/* Error means the token is valid, but the account doesn’t exist. If this is
 * observed it means the account was removed on the server but the auth token
 * is still recognized as valid by the server. */
export function isAccountRemovedError(error: APIError): boolean {
    return !!(
        error &&
        error.code === 400 &&
        error.error === 'account does not exist'
    );
}

/* Whether if not the user can't recover from the error and so is forced to login the app
 * i.e trying again/chaning request data won't resolve the error.
 */
export function isUnrecoverableError(error: any): boolean {
    const errorHandlers = [
        isCannotBuildUrlError,
        isInvalidAuthToken,
        isClientRoleRequiredError,
        isAuthorizationRequiredError,
        isAccountRemovedError,
    ];

    for (const errorHandler of errorHandlers) {
        if (errorHandler(error)) {
            return true;
        }
    }
    return false;
}

export function isAccountNotFoundError(error: any) {
    const apiError = getAPIError(error);
    return error.code == 404 && error.topic === 'ACCOUNT';
}
