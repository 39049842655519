import { DateTime } from 'luxon';

import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { UrlService, ActivityListUrlOptions } from './url.service';

import { InvalidServerResponse, getAPIError } from '@app/services/error';

import {
    Activity,
    ActivityRef,
    RecordedActivity,
    RecordedActivityRef,
    asActivity,
    asActivityArray,
    isActivity,
    isActivityThisWeek,
    isActivityCompletable,
    isActivityUpcoming,
    filterByWeek,
    ActivityStatus,
    isActivityPending,
    isActivitySkipped,
    isActivityCompleted,
} from './activity-model';

import { ActivityActiveCache } from './activity-cache';

import { ActivityWeeklyCacheService } from './activity-weekly-cache.service';

export {
    Activity,
    ActivityRef,
    RecordedActivity,
    RecordedActivityRef,
    asActivity,
    asActivityArray,
    isActivity,
    isActivityThisWeek,
    isActivityCompletable,
    isActivityUpcoming,
    filterByWeek,
    ActivityStatus,
    isActivityPending,
    isActivitySkipped,
    isActivityCompleted,
};

import { getWeekStart, getWeekEnd } from '@app/date-utils';

// How often (minimum time) to refresh the cache with data from the servers
const SCHEDULE_CACHE_COOLDOWN_MS = 60 * 1000;

export interface CreateActivityDetails {
    startTime: string;
    durationMinutes: number;
    details: string;
    recorded?: RecordedActivityRef;
    status?: ActivityStatus;
}

export interface UpdateRecordedActivityDetails {
    mood: number;
    effort: number;
    actualDurationMinutes: number;
}

export interface UpdateActivityDetails {
    startTime?: string;
    durationMinutes?: number;
    details?: string;
    recorded?: UpdateRecordedActivityDetails | null;
    status?: ActivityStatus;
}

@Injectable({
    providedIn: 'root',
})
export class ActivityService {
    static readonly MAX_COMMITTED_ACTIVITIES_PER_WEEK: number = 200; // Max committed activities in a week is 200
    static readonly MAX_DURATION_PER_ACTIVITY_IN_MINUTES: number = 960; // Max duration of a single activity in minutes

    static readonly MOOD_LABELS = [
        'Disheartened',
        'Ho-Hum',
        'Satisfied',
        'Really Good',
        'Awesome',
    ];

    static readonly EFFORT_LABELS = [
        'Relaxed',
        'Light',
        'Moderate',
        'Vigorous',
        'Maximum',
    ];

    // Cache of "active" activities (available for modification)
    activeCache: ActivityActiveCache = new ActivityActiveCache();

    constructor(
        private http: HttpClient,
        private urlService: UrlService,
        public weeklyCache: ActivityWeeklyCacheService
    ) {}

    async create(details: CreateActivityDetails): Promise<Activity> {
        const url = await this.urlService.getCreateActivityUrl();
        const body = {
            start_time: details.startTime,
            duration_seconds: details.durationMinutes * 60,
            details: details.details,
            status: details.status ?? ActivityStatus.PENDING,
        };
        if (details.recorded) {
            body['recorded'] = {
                duration_seconds: details.recorded.actualDurationMinutes * 60,
                mood: details.recorded.mood,
                effort: details.recorded.effort,
            };
        }

        return this.http
            .post(url, body)
            .toPromise()
            .then((payload) => {
                const activity = asActivity(payload);
                if (!activity) {
                    throw InvalidServerResponse(payload);
                }
                return activity;
            })
            .then((activity) => {
                this.activeCache.created(activity);
                this.weeklyCache.created(activity);
                return activity;
            })
            .catch((response) => {
                throw getAPIError(response);
            });
    }

    /*
     * Either create a new activity (if given is null) or update an existing
     * activity. Returns the new or updated activity via promise.
     */
    createOrUpdate(
        activity: ActivityRef,
        details: CreateActivityDetails
    ): Promise<Activity> {
        if (activity) {
            return this.update(activity.id, details);
        }
        return this.create(details);
    }

    /*
     * Returns a list of activities given the request criteria. Note: this
     * function always hits the server and never caches results.
     */
    private async _fetch(
        options: ActivityListUrlOptions = {}
    ): Promise<readonly Activity[]> {
        const url = await this.urlService.getActivityListUrl(options);
        return this.http
            .get(url)
            .toPromise()
            .then((payload) => {
                const activities = asActivityArray(payload);
                if (!activities) {
                    throw InvalidServerResponse(payload);
                }
                return activities;
            })
            .catch((response) => {
                throw getAPIError(response);
            });
    }

    /*
     * Fetches the list of activities from the server for the schedule.
     * (starting this week and onward) Returns the list of activities sorted
     * by start time newest first.
     */
    async fetchSchedule(): Promise<readonly Activity[]> {
        if (this.activeCache.isValid) {
            return this.activeCache.activities;
        }
        return this._fetch({
            after: getWeekStart(DateTime.now()),
        }).then((activities) => {
            return this.activeCache.set(activities);
        });
    }

    /*
     * Check if there's any new activities or updates on the service since
     * the cache was last updated. This function operates on a cooldown and is
     * safe to call repeatedly and it won't hammer the server with requests.
     */
    async refreshSchedule(): Promise<void> {
        if (!this.activeCache.isValid) {
            await this.fetchSchedule();
            return;
        }
        if (this.activeCache.populatedAgeMS >= SCHEDULE_CACHE_COOLDOWN_MS) {
            await this._fetch({
                after: getWeekStart(DateTime.now()),
                updatedAfter: this.activeCache.lastPopulated,
            }).then((activities) => {
                return this.activeCache.merge(activities);
            });
        }
    }

    /*
     * Returns a list of activities scheduled during a given week. (via promise)
     * The results are cached (by week) and used in future calls.
     */
    fetchWeek(weekStart: DateTime): Promise<readonly Activity[]> {
        return this.fetchWeeks(weekStart, 1);
    }

    /*
     * Fetch the given number of weeks from the server, or local cache if
     * available. If positive, this fetches that number of weeks going forward
     * from the given date. (which is included in the count) If negative, this
     * fetches that number of weeks going backwards with the given week included
     * in the count.
     */
    async fetchWeeks(
        weekStart: DateTime,
        numWeeks: number
    ): Promise<readonly Activity[]> {
        // Returns the start of each week in which we want activity data
        function getWeekStarts(): DateTime[] {
            let actualStart = weekStart;
            if (numWeeks < 0) {
                numWeeks *= -1;
                actualStart = weekStart.minus({ weeks: numWeeks - 1 });
            }
            const weeks = [];
            for (let offset = 0; offset <= numWeeks; offset++) {
                weeks.push(actualStart.plus({ weeks: offset }));
            }
            return weeks.sort();
        }

        const weeks = getWeekStarts();
        if (weeks.length === 0) {
            return [];
        }
        // We could maybe optimize things by drawing the available data from
        // the cache, and hitting the server for the rest, but that's hard to
        // do in the general case and this is probably good enough.
        if (
            weeks.every((weekStart) => this.weeklyCache.isWeekValid(weekStart))
        ) {
            const activities = weeks
                .map((weekStart) => {
                    return this.weeklyCache.getWeek(weekStart);
                })
                .reduce((result, activities) => {
                    return result.concat(activities);
                });
            return activities;
        }
        const activities = await this._fetch({
            after: weeks[0],
            before: weeks[weeks.length - 1],
        });
        weeks.forEach((weekStart) => {
            this.weeklyCache.setWeek(
                weekStart,
                filterByWeek(activities, weekStart)
            );
        });
        return activities;
    }

    /*
     * Return an activity given the ID (via promise) first consulting the
     * cache, then requesting from the API server.
     */
    async fetchById(id: number): Promise<Activity> {
        const found = this.activeCache.find(id);
        if (found) {
            return Promise.resolve(found);
        }
        const url = await this.urlService.getActivityUrl(id);
        return this.http
            .get(url)
            .toPromise()
            .then((payload) => {
                const activity = asActivity(payload);
                if (!activity) {
                    throw InvalidServerResponse(payload);
                }
                return activity;
            })
            .catch((response) => {
                throw getAPIError(response);
            });
    }

    async update(
        id: number,
        details: UpdateActivityDetails
    ): Promise<Activity> {
        const url = await this.urlService.getActivityUrl(id);
        const body = {};
        if (details.startTime !== undefined) {
            body['start_time'] = details.startTime;
        }
        if (details.durationMinutes !== undefined) {
            body['duration_seconds'] = details.durationMinutes * 60;
        }
        if (details.details !== undefined) {
            body['details'] = details.details;
        }

        if (details.recorded) {
            // Marking complete
            if (details.status && details.status !== ActivityStatus.COMPLETE) {
                throw Error(
                    'must mark activity complete when specifying recorded field'
                );
            }
            body['status'] = ActivityStatus.COMPLETE;
            body['recorded'] = {
                mood: details.recorded.mood,
                effort: details.recorded.effort,
            };
            if (details.recorded.actualDurationMinutes !== undefined) {
                body['recorded']['duration_seconds'] =
                    details.recorded.actualDurationMinutes * 60;
            }
        } else if (details.status) {
            body['status'] = details.status;
        }

        return this.http
            .put(url, body)
            .toPromise()
            .then((payload) => {
                const activity = asActivity(payload);
                if (!activity) {
                    throw InvalidServerResponse(payload);
                }
                return activity;
            })
            .then((activity) => {
                this.activeCache.updated(activity);
                this.weeklyCache.updated(activity);
                return activity;
            })
            .catch((response) => {
                throw getAPIError(response);
            });
    }

    async delete(id: number): Promise<void> {
        const url = await this.urlService.getActivityUrl(id);
        return this.http
            .delete(url)
            .toPromise()
            .catch((response) => {
                const error = getAPIError(response);
                if (error.code === 404) {
                    // If the activity can't be found to delete, then that
                    // means it's already been deleted - update cache below.
                    return;
                }
                throw error;
            })
            .then(() => {
                this.activeCache.deleted(id);
                this.weeklyCache.deleted(id);
            });
    }

    /* Returns all active activities in the schedule cache (or empty list) */
    getActiveCached(): readonly Activity[] {
        return this.activeCache.activities;
    }

    getThisWeekCached(): readonly Activity[] {
        return this.activeCache.activitiesThisWeek;
    }

    getNextWeekCached(): readonly Activity[] {
        return this.activeCache.activitiesNextWeek;
    }

    getWeekCached(weekStart: DateTime): readonly Activity[] {
        return this.weeklyCache.getWeek(weekStart);
    }

    clear(): Promise<void> {
        return Promise.all([
            this.activeCache.clear(),
            this.weeklyCache.clear(),
        ]).then(() => {});
    }

    static get UNRECORDED_MOOD_EFFORT(): number {
        return 0;
    }
}
