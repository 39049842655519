import { v4 as uuidv4 } from 'uuid';

import { Capacitor } from '@capacitor/core';

import { Component, OnInit, NgZone } from '@angular/core';

import { Router } from '@angular/router';

import { PopoverController } from '@ionic/angular';

import {
    RegionService,
    Country,
    DEFAULT_COUNTRY_CODE,
} from '@app/services/region.service';

import { AppService } from '@app/services/app.service';

import { isPlatformWeb } from '@app/services/device.service';

import {
    AuthenticationService,
    asSSOIdentity,
} from '@app/services/authentication.service';

import {
    asInviteArray,
    getFirstClientInvite,
} from '@app/services/invite-model';

import { getAPIError, isClientRoleRequiredError } from '@app/services/error';

import { ErrorService } from '@app/services/error.service';

import { isAccountNotFoundError } from '@app/services/error';

import { RegistrationService } from '@app/services/registration.service';

import { environment } from '@app/../environments/environment';

import { Build } from '@app/../environments/build';

import {
    initGoogleSignIn,
    renderGoogleSignInButton,
    isGoogleSignIsSupported,
} from './google-sign-in';

import { SsoRegistrationPage } from '@app/pages/sso-registration/sso-registration.page';

const GOOGLE_SIGN_IN_BUTTON_ID = 'google-sign-in';

function makeDemoOAuthEmail(): string {
    return `${uuidv4()}@example.com`;
}

@Component({
    selector: 'app-logged-out',
    templateUrl: './logged-out.page.html',
    styleUrls: ['./logged-out.page.scss'],
})
export class LoggedOutPage implements OnInit {
    static demoOAuthEmail: string = '';
    countryCode: string = DEFAULT_COUNTRY_CODE;
    showingNoAccountError: boolean = false;
    isGoogleSignIsSupported = isGoogleSignIsSupported();
    attemptingSignIn: boolean = false;
    // The email address to use when running the oauth demo
    demoMagicSignInCode: string = environment.demoMagicSignInCode ?? '';

    constructor(
        private regionService: RegionService,
        private router: Router,
        private appService: AppService,
        private ngZone: NgZone,
        private authService: AuthenticationService,
        private errorService: ErrorService,
        private registrationService: RegistrationService,
        private popoverCtrl: PopoverController
    ) {}

    get hasOAuthDemo(): boolean {
        return !!environment.demoOAuthSSOTokenPrefix;
    }

    get countryList(): Country[] {
        return this.regionService.getAppSupportedCountries();
    }

    get hasSocialSignIns(): boolean {
        return this.hasOAuthDemo || this.isGoogleSignIsSupported;
    }

    get version(): string {
        return Build.VERSION;
    }

    get supportsMagicCode(): boolean {
        /* As of https://www.pivotaltracker.com/story/show/188414583 we don't
         * support magic code on the web for complicated reasons. */
        return !(environment.production && isPlatformWeb());
    }

    ngOnInit() {}

    ionViewWillEnter() {
        if (this.isGoogleSignIsSupported) {
            initGoogleSignIn((response) => {
                this.handleCredential(response['credential']);
            })
                .then(() => {
                    if (this.countryCode) {
                        renderGoogleSignInButton(GOOGLE_SIGN_IN_BUTTON_ID);
                    }
                })
                .catch((error) => {
                    console.log('could not init google sign in:', error);
                });
        }
    }

    async loginWithSSO(credential: string): Promise<void> {
        this.showingNoAccountError = false;
        try {
            await this.authService.authenticateSSO({
                country: this.countryCode,
                ssoToken: credential,
            });
        } catch (error) {
            if (error.code === 400) {
                // The sso token isn't valid
                throw getAPIError(
                    'There was a problem signing you into the app. Please try again later.'
                );
            }
            if (!isAccountNotFoundError(error) && error.code !== 401) {
                throw error;
            }
            // The sso token is valid but there is no GGF account
            const invites = asInviteArray(error['invites']) ?? [];
            const identity = asSSOIdentity(error['identity']);
            const invite = getFirstClientInvite(invites);
            if (!identity) {
                this.showingNoAccountError = true;
                return;
            }
            SsoRegistrationPage.navigate(this.router, {
                invite: invite,
                ssoToken: credential,
                name: identity.name,
                email: identity.email,
                countryCode: this.countryCode,
            });
            return;
        }
        this.appService.navigateNext();
    }

    /*
     * Called when the client authenticates with a third party using SSO. The
     * oauth credential is passed in.
     */
    handleCredential(credential: string) {
        if (!this.countryCode) {
            return;
        }
        // Note handleCredential is called by the google gsi module, so we need to let
        // angular know about this.
        this.ngZone.run(() => {
            this.attemptingSignIn = true;
            this.errorService
                .tryOnceToast(() => {
                    return this.loginWithSSO(credential);
                })
                .finally(() => {
                    this.attemptingSignIn = false;
                });
        });
    }

    onDemoSSO() {
        if (!this.hasOAuthDemo) {
            return;
        }
        if (!LoggedOutPage.demoOAuthEmail) {
            // Save the demo email so it can be reused in the same session
            // (eg. sign in, sign out and then sign in with the same email)
            LoggedOutPage.demoOAuthEmail = makeDemoOAuthEmail();
        }
        const token =
            environment.demoOAuthSSOTokenPrefix + LoggedOutPage.demoOAuthEmail;
        this.handleCredential(token);
    }

    onLogin() {
        this.router.navigate(['/login', this.countryCode]);
    }

    onSignUp() {
        this.router.navigate(['/registration', this.countryCode]);
    }

    onCountryChange() {
        renderGoogleSignInButton(GOOGLE_SIGN_IN_BUTTON_ID);
    }

    onStudentLogin() {
        this.router.navigate(['/magic-login', this.countryCode]);
    }

    onStudentSignUp() {
        this.router.navigate(['/magic-login/registration', this.countryCode]);
    }

    /**********/
    /* Static */
    /**********/

    /* Set the email to use when running the SSO demo (see app.component.ts) */
    static setDemoOAuthEmail(email: string) {
        if (environment.demoOAuthSSOTokenPrefix) {
            LoggedOutPage.demoOAuthEmail = email;
        }
    }
}
