
/* Note: this file is autogenerated by generate_build_info.js

This file contains secrets and MUST NOT be committed into the repository. */

export const Build = {
    "VERSION": "2.15.0",
    "COMMIT": "8761753",
    "DATE": "2025-01-08",
    "SECRETS": {
        "GOOGLE_MAPS_KEY": "AIzaSyClwxhkIMY4fd1dcyRPS9LWvXxpZrwVk2g",
        "FIREBASE_WEB_CONFIG": {
            "apiKey": "AIzaSyAMVKwDt0Ljy7TxpzXxDszdgsPdUkHJXoY",
            "authDomain": "go-get-fit-dev.firebaseapp.com",
            "projectId": "go-get-fit-dev",
            "storageBucket": "go-get-fit-dev.appspot.com",
            "messagingSenderId": "626899383469",
            "appId": "1:626899383469:web:1296f23af1cf6b32078224",
            "measurementId": "G-QSWVCMX80V"
        }
    }
}
