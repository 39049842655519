import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: 'blank',
        loadChildren: () =>
            import('./pages/blank/blank.module').then((m) => m.BlankPageModule),
    },
    {
        path: 'create-profile',
        loadChildren: () =>
            import('./pages/create-profile/create-profile.module').then(
                (m) => m.CreateProfilePageModule
            ),
    },
    {
        path: '',
        loadChildren: () =>
            import('./pages/logged-out/logged-out.module').then(
                (m) => m.LoggedOutPageModule
            ),
        pathMatch: 'full',
    },
    {
        path: 'registration',
        loadChildren: () =>
            import('./pages/registration/registration.module').then(
                (m) => m.RegistrationPageModule
            ),
    },
    {
        path: 'login',
        loadChildren: () =>
            import('./pages/login/login.module').then((m) => m.LoginPageModule),
    },
    {
        path: 'forgot-password',
        loadChildren: () =>
            import('./pages/forgot-password/forgot-password.module').then(
                (m) => m.ForgotPasswordPageModule
            ),
    },
    {
        path: 'feedback',
        redirectTo: '/tabs/settings/feedback',
    },
    {
        path: 'edit-commitment',
        loadChildren: () =>
            import('./pages/edit-commitment/edit-commitment.module').then(
                (m) => m.EditCommitmentPageModule
            ),
    },
    {
        path: 'home',
        loadChildren: () =>
            import('./pages/home/home.module').then((m) => m.HomePageModule),
    },
    {
        path: 'messages/:id',
        loadChildren: () =>
            import('./pages/view-message/view-message.module').then(
                (m) => m.ViewMessagePageModule
            ),
    },
    {
        path: 'tabs',
        loadChildren: () =>
            import('./pages/tabs/tabs.module').then((m) => m.TabsPageModule),
    },
    {
        path: 'messages',
        loadChildren: () =>
            import('./pages/message-center/message-center.module').then(
                (m) => m.MessageCenterPageModule
            ),
    },
    {
        path: 'schedule-overview',
        loadChildren: () =>
            import('./pages/schedule-overview/schedule-overview.module').then(
                (m) => m.ScheduleOverviewPageModule
            ),
    },
    {
        path: 'settings-notification',
        redirectTo: '/tabs/settings/settings-notification',
    },
    {
        path: 'commitment-overview',
        loadChildren: () =>
            import(
                './pages/commitment-overview/commitment-overview.module'
            ).then((m) => m.CommitmentOverviewPageModule),
    },
    {
        path: 'settings',
        loadChildren: () =>
            import('./pages/settings/settings.module').then(
                (m) => m.SettingsPageModule
            ),
    },
    {
        path: 'try-again',
        loadChildren: () =>
            import('./pages/try-again/try-again.module').then(
                (m) => m.TryAgainPageModule
            ),
    },
    {
        path: 'connection-consent',
        loadChildren: () =>
            import('./pages/connection-consent/connection-consent.module').then(
                (m) => m.ConnectionConsentPageModule
            ),
    },
    {
        path: 'history',
        loadChildren: () =>
            import('./pages/history/history.module').then(
                (m) => m.HistoryPageModule
            ),
    },
    {
        path: 'history-detail',
        loadChildren: () =>
            import('./pages/history-detail/history-detail.module').then(
                (m) => m.HistoryDetailPageModule
            ),
    },
    {
        path: 'notifications-opt-in',
        loadChildren: () =>
            import(
                './pages/notifications-opt-in/notifications-opt-in.module'
            ).then((m) => m.NotificationsOptInPageModule),
    },
    {
        path: 'local-pros',
        loadChildren: () =>
            import('./pages/local-pros/local-pros.module').then(
                (m) => m.LocalProsModule
            ),
    },
    {
        path: 'account-deletion',
        redirectTo: '/tabs/settings/account-deletion',
    },
    {
        path: 'change-password',
        redirectTo: '/tabs/settings/change-password',
    },
    {
        path: 'activity',
        loadChildren: () =>
            import('./pages/activity/activity.module').then(
                (m) => m.ActivityPageModule
            ),
    },
    {
        path: 'connection-management',
        loadChildren: () =>
            import(
                './pages/connection-management/connection-management.module'
            ).then((m) => m.ConnectionManagementPageModule),
    },
    {
        path: 'email-registration',
        loadChildren: () =>
            import('./pages/sso-registration/sso-registration.module').then(
                (m) => m.SsoRegistrationPageModule
            ),
    },
    {
        path: 'magic-login',
        loadChildren: () =>
            import('./pages/magic-login/magic-login.module').then(
                (m) => m.MagicLoginPageModule
            ),
    },
    {
        path: 'magic-verify',
        loadChildren: () =>
            import('./pages/magic-verify/magic-verify.module').then(
                (m) => m.MagicVerifyPageModule
            ),
    },
    {
        path: 'accept-terms',
        loadChildren: () =>
            import('./pages/accept-terms/accept-terms.module').then(
                (m) => m.AcceptTermsPageModule
            ),
    },
    {
        path: 'review-pending',
        loadChildren: () =>
            import('./app/pages/review-pending/review-pending.module').then(
                (m) => m.ReviewPendingPageModule
            ),
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
